import React from 'react'
import {
  Paper,
  LinearProgress,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material'
import * as mo from 'moment'
import { withStyles } from '@mui/styles'
import { Snack, ModalCust } from './Lib'
import {
  // styleGuide,
  menuHeaderTvHig,
  menuHeaderTv,
  menuListHeaderTv,
  menuListHeaderSmalTv,
  menuListSecTv,
  menuDivider,
  higBotDivider,
  higTopDivider,
  menuWhiteBg,
  menuWhiteLogo,
  menuList,
  menuListHighlight,
  menuListHighlightSpannabis,
  menuListMenuScreen
} from './Style'
import __ from '../util'

class MenuScreen2 extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      bgColor: mo().hour() > 19 ? '#37493E' : '#5E7D6B',
      // bgColor: '#5E7D6B',
      menu: undefined
    }
    this.state = {
      ...this.reset
    }
    this.getMenu = this.getMenu.bind(this)
    this.updateRunning = false
  }

  async getMenu () {
    let menu

    if(!this.updateRunning) {
      this.updateRunning = true
      try {
        [menu] = await Promise.all([
          this.cx.core.getMenuScreen(2)
        ])
      } catch (e) {
        if (__.cfg('isDev')) throw e
        /*const emsg = e.message
        __.clearSto()
        this.setState({ err: emsg })
        throw e*/
        console.log(e)
      }
      if(Array.isArray(menu)) {
        this.setState({
          bgColor: mo().hour() > 19 ? '#37493E' : '#5E7D6B',
          // bgColor: '#5E7D6B',
          menu
        })
      }
      this.updateRunning = false
    } else {
      // console.log('skip');
    }
    if(!this.interval) {
        this.interval = setInterval(() => this.getMenu(), __.cfg('refreshMenuView'))
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'menu'))

    // set body bg
    document.body.style.backgroundColor = this.state.bgColor

    await this.getMenu()
  }

  componentWillUnmount() {
    // console.log('hi unmount', this.interval)
    if(this.interval) {
      clearInterval(this.interval)
    }
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.menu && this.state.menu.length > 0) {
      return (
        <Paper square elevation={0} style={{backgroundColor: this.state.bgColor, height: '100vh'}}>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <div style={{ margin: 0, position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%'}}>
            <Grid container justifyContent='center' spacing={2} style={{padding:20}}>
              <Grid item xs={12}>
                <img
                  src='//cdn.higcsc.com/menu/hig-div-trans-top.png'
                  alt='Btm Div'
                  className={this.props.classes.higTopDivider}
                  style={{marginBottom: 20}}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv}>Pre-Rolled Joints</Typography>
                <List className={this.props.classes.menuList}>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            JOINT LIADO ESTÁNDAR
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>A partir de las cepas favoritas de nuestros socios</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            JOINT PACK
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>4 joint liados por el precio de 3</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            CBD Joint Liado
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Elaborado con nuestras mejores cepas de CBD</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv} style={{marginTop: 96}}>Specials</Typography>
                <List className={this.props.classes.menuList}>
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            Vape Pen
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Batería recargable y cartucho de THC de 1ml</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            Extracciones
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Rosin casero y Wax de alta concentración</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                  <ListItem className={this.props.classes.menuList}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            THC Gummies
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>Gominolas con 30mg o 60mg</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv}>Hashish</Typography>
                {this.state.menu.filter((el) => el.idCategory === 4).map((item) => <List key={item.idItem} className={this.props.classes.menuList}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlight : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTvHig}>CBD</Typography>
                {this.state.menu.filter((el) => el.idCategory === 11 && el.activeMenu === 1 && el.activeChoice !== 1).map((item) => <List key={item.idItem} className={this.props.classes.menuList}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlight : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
                <Typography variant='h2' align='center' className={this.props.classes.menuHeaderTv} style={{marginTop: 96}}>Premium Hash</Typography>
                {this.state.menu.filter((el) => el.idCategory === 19).map((item) => <List key={item.idItem} className={this.props.classes.menuList}>
                  <ListItem className={(item.spannabis === 1 ? this.props.classes.menuListHighlightSpannabis + ' ' : '') + (item.highlight === 1 ? this.props.classes.menuListHighlight : this.props.classes.menuList)}>
                    <ListItemText
                      disableTypography
                      className={this.props.classes.menuListMenuScreen}
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='body2'
                            className={this.props.classes.menuListHeaderTv}
                            color='textPrimary'
                          >
                            {item.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={<Typography variant='caption' display='block' className={this.props.classes.menuListSecTv}>{item.parents}</Typography>}
                    />
                  </ListItem>
                  <Divider className={this.props.classes.menuDivider} />
                </List>)}
              </Grid>
              <Grid item xs={12}>
                <img
                  src='//cdn.higcsc.com/menu/hig-div-trans-bot.png'
                  alt='Btm Div'
                  className={this.props.classes.higBotDivider}
                  style={{marginTop: 20}}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      )
    } else if(this.state.loading) {
      return <LinearProgress />
    } else {
      return <Paper square elevation={0} className={this.props.classes.menuWhiteBg}>
        <img
          src='//cdn.higcsc.com/higlogo-trans.png'
          alt='Logo HIG CSC'
          className={this.props.classes.menuWhiteLogo}
        />
      </Paper>
    }
  }
}

export default
  withStyles({
    menuHeaderTvHig,
    menuHeaderTv,
    menuListHeaderTv,
    menuListHeaderSmalTv,
    menuListSecTv,
    menuDivider,
    higBotDivider,
    higTopDivider,
    menuWhiteBg,
    menuWhiteLogo,
    menuList,
    menuListHighlight,
    menuListHighlightSpannabis,
    menuListMenuScreen
  })(MenuScreen2)
